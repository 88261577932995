import ApiService from './api.service';

const serviceURL = 'reset/project';
const ResetService = {
	/**
	 * Acquire delivery by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},
};

export default ResetService;
