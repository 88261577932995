<template>
	<div>
		<h3>データベースのリセット画面</h3>
		<div class="reset">
			<input
				type="number"
				placeholder="プロジェクトIDを入力してください..."
				v-model="projectId"
			/>
			<button @click="onClick">リセット</button>
		</div>
	</div>
</template>

<script>
import ResetService from '../../services/API/reset.service';
export default {
	name: 'ResetScreen',
	data() {
		return {
			projectId: '',
		};
	},
	methods: {
		async onClick() {
			let projectId = this.projectId;
			if (projectId) {
				await this.resetDatabase(projectId);
			} else {
				alert('プロジェクトIDを入力してください');
			}
		},

		async resetDatabase(projectId) {
			try {
				let response = await ResetService.get(projectId);
				if (response.status === 200) {
					alert(
						'プロジェクトID: ' +
							`${projectId}` +
							'がリセットされました。'
					);
				}
			} catch (error) {
				console.log(error);
				alert(
					'プロジェクトID: ' + `${projectId}` + 'がリセットできません'
				);
			}
		},
	},
};
</script>

<style src="./ResetScreen.css" scoped></style>
